/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { SeriesData } from "./SeriesData";
import {
  SeriesDataFromJSON,
  SeriesDataFromJSONTyped,
  SeriesDataToJSON,
} from "./SeriesData";

/**
 *
 * @export
 * @interface HistoricalValuation
 */
export interface HistoricalValuation {
  /**
   *
   * @type {string}
   * @memberof HistoricalValuation
   */
  valuationCcy: string;
  /**
   *
   * @type {SeriesData}
   * @memberof HistoricalValuation
   */
  seriesData: SeriesData;
}

/**
 * Check if a given object implements the HistoricalValuation interface.
 */
export function instanceOfHistoricalValuation(
  value: object,
): value is HistoricalValuation {
  if (!("valuationCcy" in value) || value["valuationCcy"] === undefined)
    return false;
  if (!("seriesData" in value) || value["seriesData"] === undefined)
    return false;
  return true;
}

export function HistoricalValuationFromJSON(json: any): HistoricalValuation {
  return HistoricalValuationFromJSONTyped(json, false);
}

export function HistoricalValuationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HistoricalValuation {
  if (json == null) {
    return json;
  }
  return {
    valuationCcy: json["valuation_ccy"],
    seriesData: SeriesDataFromJSON(json["series_data"]),
  };
}

export function HistoricalValuationToJSON(
  value?: HistoricalValuation | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    valuation_ccy: value["valuationCcy"],
    series_data: SeriesDataToJSON(value["seriesData"]),
  };
}
