/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { Metrics } from "./Metrics";
import {
  MetricsFromJSON,
  MetricsFromJSONTyped,
  MetricsToJSON,
} from "./Metrics";
import type { AggregationDescription } from "./AggregationDescription";
import {
  AggregationDescriptionFromJSON,
  AggregationDescriptionFromJSONTyped,
  AggregationDescriptionToJSON,
} from "./AggregationDescription";

/**
 *
 * @export
 * @interface ReportEntry
 */
export interface ReportEntry {
  /**
   *
   * @type {AggregationDescription}
   * @memberof ReportEntry
   */
  aggregation: AggregationDescription;
  /**
   *
   * @type {Metrics}
   * @memberof ReportEntry
   */
  metrics: Metrics;
}

/**
 * Check if a given object implements the ReportEntry interface.
 */
export function instanceOfReportEntry(value: object): value is ReportEntry {
  if (!("aggregation" in value) || value["aggregation"] === undefined)
    return false;
  if (!("metrics" in value) || value["metrics"] === undefined) return false;
  return true;
}

export function ReportEntryFromJSON(json: any): ReportEntry {
  return ReportEntryFromJSONTyped(json, false);
}

export function ReportEntryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReportEntry {
  if (json == null) {
    return json;
  }
  return {
    aggregation: AggregationDescriptionFromJSON(json["aggregation"]),
    metrics: MetricsFromJSON(json["metrics"]),
  };
}

export function ReportEntryToJSON(value?: ReportEntry | null): any {
  if (value == null) {
    return value;
  }
  return {
    aggregation: AggregationDescriptionToJSON(value["aggregation"]),
    metrics: MetricsToJSON(value["metrics"]),
  };
}
