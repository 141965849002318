/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface EmailDeliverySettings
 */
export interface EmailDeliverySettings {
  /**
   *
   * @type {string}
   * @memberof EmailDeliverySettings
   */
  subjectPrefix: string;
  /**
   *
   * @type {string}
   * @memberof EmailDeliverySettings
   */
  senderName: string;
  /**
   *
   * @type {string}
   * @memberof EmailDeliverySettings
   */
  providerId: string;
  /**
   *
   * @type {object}
   * @memberof EmailDeliverySettings
   */
  providerSettings: object;
}

/**
 * Check if a given object implements the EmailDeliverySettings interface.
 */
export function instanceOfEmailDeliverySettings(
  value: object,
): value is EmailDeliverySettings {
  if (!("subjectPrefix" in value) || value["subjectPrefix"] === undefined)
    return false;
  if (!("senderName" in value) || value["senderName"] === undefined)
    return false;
  if (!("providerId" in value) || value["providerId"] === undefined)
    return false;
  if (!("providerSettings" in value) || value["providerSettings"] === undefined)
    return false;
  return true;
}

export function EmailDeliverySettingsFromJSON(
  json: any,
): EmailDeliverySettings {
  return EmailDeliverySettingsFromJSONTyped(json, false);
}

export function EmailDeliverySettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EmailDeliverySettings {
  if (json == null) {
    return json;
  }
  return {
    subjectPrefix: json["subject_prefix"],
    senderName: json["sender_name"],
    providerId: json["provider_id"],
    providerSettings: json["provider_settings"],
  };
}

export function EmailDeliverySettingsToJSON(
  value?: EmailDeliverySettings | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    subject_prefix: value["subjectPrefix"],
    sender_name: value["senderName"],
    provider_id: value["providerId"],
    provider_settings: value["providerSettings"],
  };
}
