/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { EmailDeliverySettings } from "./EmailDeliverySettings";
import {
  EmailDeliverySettingsFromJSON,
  EmailDeliverySettingsFromJSONTyped,
  EmailDeliverySettingsToJSON,
} from "./EmailDeliverySettings";

/**
 *
 * @export
 * @interface AppGetEmailDeliverySettingsResponse
 */
export interface AppGetEmailDeliverySettingsResponse {
  /**
   *
   * @type {EmailDeliverySettings}
   * @memberof AppGetEmailDeliverySettingsResponse
   */
  settings?: EmailDeliverySettings;
}

/**
 * Check if a given object implements the AppGetEmailDeliverySettingsResponse interface.
 */
export function instanceOfAppGetEmailDeliverySettingsResponse(
  value: object,
): value is AppGetEmailDeliverySettingsResponse {
  return true;
}

export function AppGetEmailDeliverySettingsResponseFromJSON(
  json: any,
): AppGetEmailDeliverySettingsResponse {
  return AppGetEmailDeliverySettingsResponseFromJSONTyped(json, false);
}

export function AppGetEmailDeliverySettingsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppGetEmailDeliverySettingsResponse {
  if (json == null) {
    return json;
  }
  return {
    settings:
      json["settings"] == null
        ? undefined
        : EmailDeliverySettingsFromJSON(json["settings"]),
  };
}

export function AppGetEmailDeliverySettingsResponseToJSON(
  value?: AppGetEmailDeliverySettingsResponse | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    settings: EmailDeliverySettingsToJSON(value["settings"]),
  };
}
