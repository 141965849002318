/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GroupValuation
 */
export interface GroupValuation {
  /**
   *
   * @type {string}
   * @memberof GroupValuation
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GroupValuation
   */
  colour: string;
  /**
   *
   * @type {number}
   * @memberof GroupValuation
   */
  value: number;
}

/**
 * Check if a given object implements the GroupValuation interface.
 */
export function instanceOfGroupValuation(
  value: object,
): value is GroupValuation {
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("colour" in value) || value["colour"] === undefined) return false;
  if (!("value" in value) || value["value"] === undefined) return false;
  return true;
}

export function GroupValuationFromJSON(json: any): GroupValuation {
  return GroupValuationFromJSONTyped(json, false);
}

export function GroupValuationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GroupValuation {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"],
    colour: json["colour"],
    value: json["value"],
  };
}

export function GroupValuationToJSON(value?: GroupValuation | null): any {
  if (value == null) {
    return value;
  }
  return {
    name: value["name"],
    colour: value["colour"],
    value: value["value"],
  };
}
