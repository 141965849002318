/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SubAccountItemNodeIcon
 */
export interface SubAccountItemNodeIcon {
  /**
   *
   * @type {string}
   * @memberof SubAccountItemNodeIcon
   */
  backgroundColour: string;
  /**
   *
   * @type {string}
   * @memberof SubAccountItemNodeIcon
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof SubAccountItemNodeIcon
   */
  tooltip: string;
}

/**
 * Check if a given object implements the SubAccountItemNodeIcon interface.
 */
export function instanceOfSubAccountItemNodeIcon(
  value: object,
): value is SubAccountItemNodeIcon {
  if (!("backgroundColour" in value) || value["backgroundColour"] === undefined)
    return false;
  if (!("label" in value) || value["label"] === undefined) return false;
  if (!("tooltip" in value) || value["tooltip"] === undefined) return false;
  return true;
}

export function SubAccountItemNodeIconFromJSON(
  json: any,
): SubAccountItemNodeIcon {
  return SubAccountItemNodeIconFromJSONTyped(json, false);
}

export function SubAccountItemNodeIconFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SubAccountItemNodeIcon {
  if (json == null) {
    return json;
  }
  return {
    backgroundColour: json["background_colour"],
    label: json["label"],
    tooltip: json["tooltip"],
  };
}

export function SubAccountItemNodeIconToJSON(
  value?: SubAccountItemNodeIcon | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    background_colour: value["backgroundColour"],
    label: value["label"],
    tooltip: value["tooltip"],
  };
}
