/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AppUpdateUserAccountPasswordRequest
 */
export interface AppUpdateUserAccountPasswordRequest {
  /**
   *
   * @type {string}
   * @memberof AppUpdateUserAccountPasswordRequest
   */
  oldPassword: string;
  /**
   *
   * @type {string}
   * @memberof AppUpdateUserAccountPasswordRequest
   */
  newPassword: string;
}

/**
 * Check if a given object implements the AppUpdateUserAccountPasswordRequest interface.
 */
export function instanceOfAppUpdateUserAccountPasswordRequest(
  value: object,
): value is AppUpdateUserAccountPasswordRequest {
  if (!("oldPassword" in value) || value["oldPassword"] === undefined)
    return false;
  if (!("newPassword" in value) || value["newPassword"] === undefined)
    return false;
  return true;
}

export function AppUpdateUserAccountPasswordRequestFromJSON(
  json: any,
): AppUpdateUserAccountPasswordRequest {
  return AppUpdateUserAccountPasswordRequestFromJSONTyped(json, false);
}

export function AppUpdateUserAccountPasswordRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppUpdateUserAccountPasswordRequest {
  if (json == null) {
    return json;
  }
  return {
    oldPassword: json["old_password"],
    newPassword: json["new_password"],
  };
}

export function AppUpdateUserAccountPasswordRequestToJSON(
  value?: AppUpdateUserAccountPasswordRequest | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    old_password: value["oldPassword"],
    new_password: value["newPassword"],
  };
}
