/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  AppGetLinkedAccountsHistoricalValuation,
  AppGetLinkedAccountsValuationResponse,
  ValidationErrorElement,
  ValuationFrequency,
} from "../models/index";
import {
  AppGetLinkedAccountsHistoricalValuationFromJSON,
  AppGetLinkedAccountsHistoricalValuationToJSON,
  AppGetLinkedAccountsValuationResponseFromJSON,
  AppGetLinkedAccountsValuationResponseToJSON,
  ValidationErrorElementFromJSON,
  ValidationErrorElementToJSON,
  ValuationFrequencyFromJSON,
  ValuationFrequencyToJSON,
} from "../models/index";

export interface GetLinkedAccountsHistoricalValuationRequest {
  userAccountId: number;
  fromTime?: Date;
  toTime?: Date;
  frequency?: ValuationFrequency;
}

export interface GetLinkedAccountsValuationRequest {
  userAccountId: number;
}

/**
 * LinkedAccountsValuationApi - interface
 *
 * @export
 * @interface LinkedAccountsValuationApiInterface
 */
export interface LinkedAccountsValuationApiInterface {
  /**
   *
   * @summary Get linked accounts historical valuation
   * @param {number} userAccountId
   * @param {Date} [fromTime]
   * @param {Date} [toTime]
   * @param {ValuationFrequency} [frequency]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinkedAccountsValuationApiInterface
   */
  getLinkedAccountsHistoricalValuationRaw(
    requestParameters: GetLinkedAccountsHistoricalValuationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AppGetLinkedAccountsHistoricalValuation>>;

  /**
   *
   * Get linked accounts historical valuation
   */
  getLinkedAccountsHistoricalValuation(
    requestParameters: GetLinkedAccountsHistoricalValuationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AppGetLinkedAccountsHistoricalValuation>;

  /**
   *
   * @summary Get linked accounts valuation
   * @param {number} userAccountId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinkedAccountsValuationApiInterface
   */
  getLinkedAccountsValuationRaw(
    requestParameters: GetLinkedAccountsValuationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AppGetLinkedAccountsValuationResponse>>;

  /**
   *
   * Get linked accounts valuation
   */
  getLinkedAccountsValuation(
    requestParameters: GetLinkedAccountsValuationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AppGetLinkedAccountsValuationResponse>;
}

/**
 *
 */
export class LinkedAccountsValuationApi
  extends runtime.BaseAPI
  implements LinkedAccountsValuationApiInterface
{
  /**
   *
   * Get linked accounts historical valuation
   */
  async getLinkedAccountsHistoricalValuationRaw(
    requestParameters: GetLinkedAccountsHistoricalValuationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AppGetLinkedAccountsHistoricalValuation>> {
    if (requestParameters["userAccountId"] == null) {
      throw new runtime.RequiredError(
        "userAccountId",
        'Required parameter "userAccountId" was null or undefined when calling getLinkedAccountsHistoricalValuation().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters["fromTime"] != null) {
      queryParameters["from_time"] = (
        requestParameters["fromTime"] as any
      ).toISOString();
    }

    if (requestParameters["toTime"] != null) {
      queryParameters["to_time"] = (
        requestParameters["toTime"] as any
      ).toISOString();
    }

    if (requestParameters["frequency"] != null) {
      queryParameters["frequency"] = requestParameters["frequency"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/v1/accounts/{user_account_id}/linked_accounts/valuation/history/`.replace(
          `{${"user_account_id"}}`,
          encodeURIComponent(String(requestParameters["userAccountId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AppGetLinkedAccountsHistoricalValuationFromJSON(jsonValue),
    );
  }

  /**
   *
   * Get linked accounts historical valuation
   */
  async getLinkedAccountsHistoricalValuation(
    requestParameters: GetLinkedAccountsHistoricalValuationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AppGetLinkedAccountsHistoricalValuation> {
    const response = await this.getLinkedAccountsHistoricalValuationRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   *
   * Get linked accounts valuation
   */
  async getLinkedAccountsValuationRaw(
    requestParameters: GetLinkedAccountsValuationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AppGetLinkedAccountsValuationResponse>> {
    if (requestParameters["userAccountId"] == null) {
      throw new runtime.RequiredError(
        "userAccountId",
        'Required parameter "userAccountId" was null or undefined when calling getLinkedAccountsValuation().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/v1/accounts/{user_account_id}/linked_accounts/valuation/`.replace(
          `{${"user_account_id"}}`,
          encodeURIComponent(String(requestParameters["userAccountId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AppGetLinkedAccountsValuationResponseFromJSON(jsonValue),
    );
  }

  /**
   *
   * Get linked accounts valuation
   */
  async getLinkedAccountsValuation(
    requestParameters: GetLinkedAccountsValuationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AppGetLinkedAccountsValuationResponse> {
    const response = await this.getLinkedAccountsValuationRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
