/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserAccountProfile
 */
export interface UserAccountProfile {
  /**
   *
   * @type {string}
   * @memberof UserAccountProfile
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserAccountProfile
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof UserAccountProfile
   */
  mobilePhoneNumber?: string;
}

/**
 * Check if a given object implements the UserAccountProfile interface.
 */
export function instanceOfUserAccountProfile(
  value: object,
): value is UserAccountProfile {
  if (!("email" in value) || value["email"] === undefined) return false;
  if (!("fullName" in value) || value["fullName"] === undefined) return false;
  return true;
}

export function UserAccountProfileFromJSON(json: any): UserAccountProfile {
  return UserAccountProfileFromJSONTyped(json, false);
}

export function UserAccountProfileFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserAccountProfile {
  if (json == null) {
    return json;
  }
  return {
    email: json["email"],
    fullName: json["full_name"],
    mobilePhoneNumber:
      json["mobile_phone_number"] == null
        ? undefined
        : json["mobile_phone_number"],
  };
}

export function UserAccountProfileToJSON(
  value?: UserAccountProfile | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    email: value["email"],
    full_name: value["fullName"],
    mobile_phone_number: value["mobilePhoneNumber"],
  };
}
