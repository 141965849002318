/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { Provider } from "./Provider";
import {
  ProviderFromJSON,
  ProviderFromJSONTyped,
  ProviderToJSON,
} from "./Provider";

/**
 *
 * @export
 * @interface AppGetProvidersResponse
 */
export interface AppGetProvidersResponse {
  /**
   *
   * @type {Array<Provider>}
   * @memberof AppGetProvidersResponse
   */
  providers: Array<Provider>;
}

/**
 * Check if a given object implements the AppGetProvidersResponse interface.
 */
export function instanceOfAppGetProvidersResponse(
  value: object,
): value is AppGetProvidersResponse {
  if (!("providers" in value) || value["providers"] === undefined) return false;
  return true;
}

export function AppGetProvidersResponseFromJSON(
  json: any,
): AppGetProvidersResponse {
  return AppGetProvidersResponseFromJSONTyped(json, false);
}

export function AppGetProvidersResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppGetProvidersResponse {
  if (json == null) {
    return json;
  }
  return {
    providers: (json["providers"] as Array<any>).map(ProviderFromJSON),
  };
}

export function AppGetProvidersResponseToJSON(
  value?: AppGetProvidersResponse | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    providers: (value["providers"] as Array<any>).map(ProviderToJSON),
  };
}
