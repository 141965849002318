/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { ValuationChange } from "./ValuationChange";
import {
  ValuationChangeFromJSON,
  ValuationChangeFromJSONTyped,
  ValuationChangeToJSON,
} from "./ValuationChange";

/**
 *
 * @export
 * @interface ValuationWithSparkline
 */
export interface ValuationWithSparkline {
  /**
   *
   * @type {string}
   * @memberof ValuationWithSparkline
   */
  currency: string;
  /**
   *
   * @type {number}
   * @memberof ValuationWithSparkline
   */
  value: number;
  /**
   *
   * @type {ValuationChange}
   * @memberof ValuationWithSparkline
   */
  change: ValuationChange;
  /**
   *
   * @type {Array<number>}
   * @memberof ValuationWithSparkline
   */
  sparkline: Array<number>;
}

/**
 * Check if a given object implements the ValuationWithSparkline interface.
 */
export function instanceOfValuationWithSparkline(
  value: object,
): value is ValuationWithSparkline {
  if (!("currency" in value) || value["currency"] === undefined) return false;
  if (!("value" in value) || value["value"] === undefined) return false;
  if (!("change" in value) || value["change"] === undefined) return false;
  if (!("sparkline" in value) || value["sparkline"] === undefined) return false;
  return true;
}

export function ValuationWithSparklineFromJSON(
  json: any,
): ValuationWithSparkline {
  return ValuationWithSparklineFromJSONTyped(json, false);
}

export function ValuationWithSparklineFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ValuationWithSparkline {
  if (json == null) {
    return json;
  }
  return {
    currency: json["currency"],
    value: json["value"],
    change: ValuationChangeFromJSON(json["change"]),
    sparkline: json["sparkline"],
  };
}

export function ValuationWithSparklineToJSON(
  value?: ValuationWithSparkline | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    currency: value["currency"],
    value: value["value"],
    change: ValuationChangeToJSON(value["change"]),
    sparkline: value["sparkline"],
  };
}
