/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DataInner
 */
export interface DataInner {}

/**
 * Check if a given object implements the DataInner interface.
 */
export function instanceOfDataInner(value: object): value is DataInner {
  return true;
}

export function DataInnerFromJSON(json: any): DataInner {
  return DataInnerFromJSONTyped(json, false);
}

export function DataInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DataInner {
  return json;
}

export function DataInnerToJSON(value?: DataInner | null): any {
  return value;
}
