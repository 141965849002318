/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { ErrorMetadata } from "./ErrorMetadata";
import {
  ErrorMetadataFromJSON,
  ErrorMetadataFromJSONTyped,
  ErrorMetadataToJSON,
} from "./ErrorMetadata";

/**
 *
 * @export
 * @interface LinkedAccountStatusErrorEntry
 */
export interface LinkedAccountStatusErrorEntry {
  /**
   *
   * @type {string}
   * @memberof LinkedAccountStatusErrorEntry
   */
  scope: string;
  /**
   *
   * @type {ErrorMetadata}
   * @memberof LinkedAccountStatusErrorEntry
   */
  error: ErrorMetadata;
}

/**
 * Check if a given object implements the LinkedAccountStatusErrorEntry interface.
 */
export function instanceOfLinkedAccountStatusErrorEntry(
  value: object,
): value is LinkedAccountStatusErrorEntry {
  if (!("scope" in value) || value["scope"] === undefined) return false;
  if (!("error" in value) || value["error"] === undefined) return false;
  return true;
}

export function LinkedAccountStatusErrorEntryFromJSON(
  json: any,
): LinkedAccountStatusErrorEntry {
  return LinkedAccountStatusErrorEntryFromJSONTyped(json, false);
}

export function LinkedAccountStatusErrorEntryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LinkedAccountStatusErrorEntry {
  if (json == null) {
    return json;
  }
  return {
    scope: json["scope"],
    error: ErrorMetadataFromJSON(json["error"]),
  };
}

export function LinkedAccountStatusErrorEntryToJSON(
  value?: LinkedAccountStatusErrorEntry | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    scope: value["scope"],
    error: ErrorMetadataToJSON(value["error"]),
  };
}
