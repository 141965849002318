/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  AppLoginRequest,
  AppLoginResponse,
  ValidationErrorElement,
} from "../models/index";
import {
  AppLoginRequestFromJSON,
  AppLoginRequestToJSON,
  AppLoginResponseFromJSON,
  AppLoginResponseToJSON,
  ValidationErrorElementFromJSON,
  ValidationErrorElementToJSON,
} from "../models/index";

export interface AuthenticateUserRequest {
  appLoginRequest?: AppLoginRequest;
}

/**
 * AuthenticationApi - interface
 *
 * @export
 * @interface AuthenticationApiInterface
 */
export interface AuthenticationApiInterface {
  /**
   *
   * @summary Authenticate user
   * @param {AppLoginRequest} [appLoginRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApiInterface
   */
  authenticateUserRaw(
    requestParameters: AuthenticateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AppLoginResponse>>;

  /**
   *
   * Authenticate user
   */
  authenticateUser(
    requestParameters: AuthenticateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AppLoginResponse>;
}

/**
 *
 */
export class AuthenticationApi
  extends runtime.BaseAPI
  implements AuthenticationApiInterface
{
  /**
   *
   * Authenticate user
   */
  async authenticateUserRaw(
    requestParameters: AuthenticateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AppLoginResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/v1/auth/login/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: AppLoginRequestToJSON(requestParameters["appLoginRequest"]),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AppLoginResponseFromJSON(jsonValue),
    );
  }

  /**
   *
   * Authenticate user
   */
  async authenticateUser(
    requestParameters: AuthenticateUserRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AppLoginResponse> {
    const response = await this.authenticateUserRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
