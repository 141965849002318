/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PlaidSettings
 */
export interface PlaidSettings {
  /**
   *
   * @type {string}
   * @memberof PlaidSettings
   */
  environment: string;
  /**
   *
   * @type {string}
   * @memberof PlaidSettings
   */
  clientId: string;
  /**
   *
   * @type {string}
   * @memberof PlaidSettings
   */
  publicKey: string;
}

/**
 * Check if a given object implements the PlaidSettings interface.
 */
export function instanceOfPlaidSettings(value: object): value is PlaidSettings {
  if (!("environment" in value) || value["environment"] === undefined)
    return false;
  if (!("clientId" in value) || value["clientId"] === undefined) return false;
  if (!("publicKey" in value) || value["publicKey"] === undefined) return false;
  return true;
}

export function PlaidSettingsFromJSON(json: any): PlaidSettings {
  return PlaidSettingsFromJSONTyped(json, false);
}

export function PlaidSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PlaidSettings {
  if (json == null) {
    return json;
  }
  return {
    environment: json["environment"],
    clientId: json["client_id"],
    publicKey: json["public_key"],
  };
}

export function PlaidSettingsToJSON(value?: PlaidSettings | null): any {
  if (value == null) {
    return value;
  }
  return {
    environment: value["environment"],
    client_id: value["clientId"],
    public_key: value["publicKey"],
  };
}
