/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { GroupValuation } from "./GroupValuation";
import {
  GroupValuationFromJSON,
  GroupValuationFromJSONTyped,
  GroupValuationToJSON,
} from "./GroupValuation";

/**
 *
 * @export
 * @interface ValuationByAssetType
 */
export interface ValuationByAssetType {
  /**
   *
   * @type {string}
   * @memberof ValuationByAssetType
   */
  valuationCcy: string;
  /**
   *
   * @type {Array<GroupValuation>}
   * @memberof ValuationByAssetType
   */
  byAssetType: Array<GroupValuation>;
}

/**
 * Check if a given object implements the ValuationByAssetType interface.
 */
export function instanceOfValuationByAssetType(
  value: object,
): value is ValuationByAssetType {
  if (!("valuationCcy" in value) || value["valuationCcy"] === undefined)
    return false;
  if (!("byAssetType" in value) || value["byAssetType"] === undefined)
    return false;
  return true;
}

export function ValuationByAssetTypeFromJSON(json: any): ValuationByAssetType {
  return ValuationByAssetTypeFromJSONTyped(json, false);
}

export function ValuationByAssetTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ValuationByAssetType {
  if (json == null) {
    return json;
  }
  return {
    valuationCcy: json["valuation_ccy"],
    byAssetType: (json["by_asset_type"] as Array<any>).map(
      GroupValuationFromJSON,
    ),
  };
}

export function ValuationByAssetTypeToJSON(
  value?: ValuationByAssetType | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    valuation_ccy: value["valuationCcy"],
    by_asset_type: (value["byAssetType"] as Array<any>).map(
      GroupValuationToJSON,
    ),
  };
}
