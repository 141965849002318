/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { Value } from "./Value";
import { ValueFromJSON, ValueFromJSONTyped, ValueToJSON } from "./Value";

/**
 *
 * @export
 * @interface SubAccountItemMetadataNode
 */
export interface SubAccountItemMetadataNode {
  /**
   *
   * @type {string}
   * @memberof SubAccountItemMetadataNode
   */
  role?: SubAccountItemMetadataNodeRoleEnum;
  /**
   *
   * @type {string}
   * @memberof SubAccountItemMetadataNode
   */
  label: string;
  /**
   *
   * @type {Value}
   * @memberof SubAccountItemMetadataNode
   */
  value: Value;
}

/**
 * @export
 */
export const SubAccountItemMetadataNodeRoleEnum = {
  Metadata: "metadata",
} as const;
export type SubAccountItemMetadataNodeRoleEnum =
  (typeof SubAccountItemMetadataNodeRoleEnum)[keyof typeof SubAccountItemMetadataNodeRoleEnum];

/**
 * Check if a given object implements the SubAccountItemMetadataNode interface.
 */
export function instanceOfSubAccountItemMetadataNode(
  value: object,
): value is SubAccountItemMetadataNode {
  if (!("label" in value) || value["label"] === undefined) return false;
  if (!("value" in value) || value["value"] === undefined) return false;
  return true;
}

export function SubAccountItemMetadataNodeFromJSON(
  json: any,
): SubAccountItemMetadataNode {
  return SubAccountItemMetadataNodeFromJSONTyped(json, false);
}

export function SubAccountItemMetadataNodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SubAccountItemMetadataNode {
  if (json == null) {
    return json;
  }
  return {
    role: json["role"] == null ? undefined : json["role"],
    label: json["label"],
    value: ValueFromJSON(json["value"]),
  };
}

export function SubAccountItemMetadataNodeToJSON(
  value?: SubAccountItemMetadataNode | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    role: value["role"],
    label: value["label"],
    value: ValueToJSON(value["value"]),
  };
}
