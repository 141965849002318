/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  AppSystemReportResponse,
  HealthResponse,
  ValidationErrorElement,
} from "../models/index";
import {
  AppSystemReportResponseFromJSON,
  AppSystemReportResponseToJSON,
  HealthResponseFromJSON,
  HealthResponseToJSON,
  ValidationErrorElementFromJSON,
  ValidationErrorElementToJSON,
} from "../models/index";

/**
 * SystemApi - interface
 *
 * @export
 * @interface SystemApiInterface
 */
export interface SystemApiInterface {
  /**
   *
   * @summary Get system report
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApiInterface
   */
  getSystemReportRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AppSystemReportResponse>>;

  /**
   *
   * Get system report
   */
  getSystemReport(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AppSystemReportResponse>;

  /**
   *
   * @summary Is server healthy
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApiInterface
   */
  isHealthyRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<HealthResponse>>;

  /**
   *
   * Is server healthy
   */
  isHealthy(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<HealthResponse>;
}

/**
 *
 */
export class SystemApi extends runtime.BaseAPI implements SystemApiInterface {
  /**
   *
   * Get system report
   */
  async getSystemReportRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AppSystemReportResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/system_report/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AppSystemReportResponseFromJSON(jsonValue),
    );
  }

  /**
   *
   * Get system report
   */
  async getSystemReport(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AppSystemReportResponse> {
    const response = await this.getSystemReportRaw(initOverrides);
    return await response.value();
  }

  /**
   *
   * Is server healthy
   */
  async isHealthyRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<HealthResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/healthy/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HealthResponseFromJSON(jsonValue),
    );
  }

  /**
   *
   * Is server healthy
   */
  async isHealthy(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<HealthResponse> {
    const response = await this.isHealthyRaw(initOverrides);
    return await response.value();
  }
}
