/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { ValuationWithSparkline } from "./ValuationWithSparkline";
import {
  ValuationWithSparklineFromJSON,
  ValuationWithSparklineFromJSONTyped,
  ValuationWithSparklineToJSON,
} from "./ValuationWithSparkline";
import type { LinkedAccountNode } from "./LinkedAccountNode";
import {
  LinkedAccountNodeFromJSON,
  LinkedAccountNodeFromJSONTyped,
  LinkedAccountNodeToJSON,
} from "./LinkedAccountNode";

/**
 *
 * @export
 * @interface UserAccountNode
 */
export interface UserAccountNode {
  /**
   *
   * @type {string}
   * @memberof UserAccountNode
   */
  role?: UserAccountNodeRoleEnum;
  /**
   *
   * @type {ValuationWithSparkline}
   * @memberof UserAccountNode
   */
  valuation: ValuationWithSparkline;
  /**
   *
   * @type {Array<LinkedAccountNode>}
   * @memberof UserAccountNode
   */
  children: Array<LinkedAccountNode>;
}

/**
 * @export
 */
export const UserAccountNodeRoleEnum = {
  UserAccount: "user_account",
} as const;
export type UserAccountNodeRoleEnum =
  (typeof UserAccountNodeRoleEnum)[keyof typeof UserAccountNodeRoleEnum];

/**
 * Check if a given object implements the UserAccountNode interface.
 */
export function instanceOfUserAccountNode(
  value: object,
): value is UserAccountNode {
  if (!("valuation" in value) || value["valuation"] === undefined) return false;
  if (!("children" in value) || value["children"] === undefined) return false;
  return true;
}

export function UserAccountNodeFromJSON(json: any): UserAccountNode {
  return UserAccountNodeFromJSONTyped(json, false);
}

export function UserAccountNodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserAccountNode {
  if (json == null) {
    return json;
  }
  return {
    role: json["role"] == null ? undefined : json["role"],
    valuation: ValuationWithSparklineFromJSON(json["valuation"]),
    children: (json["children"] as Array<any>).map(LinkedAccountNodeFromJSON),
  };
}

export function UserAccountNodeToJSON(value?: UserAccountNode | null): any {
  if (value == null) {
    return value;
  }
  return {
    role: value["role"],
    valuation: ValuationWithSparklineToJSON(value["valuation"]),
    children: (value["children"] as Array<any>).map(LinkedAccountNodeToJSON),
  };
}
