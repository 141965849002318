/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserAccountSettings
 */
export interface UserAccountSettings {
  /**
   *
   * @type {string}
   * @memberof UserAccountSettings
   */
  valuationCcy: string;
  /**
   *
   * @type {Date}
   * @memberof UserAccountSettings
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof UserAccountSettings
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the UserAccountSettings interface.
 */
export function instanceOfUserAccountSettings(
  value: object,
): value is UserAccountSettings {
  if (!("valuationCcy" in value) || value["valuationCcy"] === undefined)
    return false;
  if (!("createdAt" in value) || value["createdAt"] === undefined) return false;
  return true;
}

export function UserAccountSettingsFromJSON(json: any): UserAccountSettings {
  return UserAccountSettingsFromJSONTyped(json, false);
}

export function UserAccountSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserAccountSettings {
  if (json == null) {
    return json;
  }
  return {
    valuationCcy: json["valuation_ccy"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
  };
}

export function UserAccountSettingsToJSON(
  value?: UserAccountSettings | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    valuation_ccy: value["valuationCcy"],
    created_at: value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
  };
}
