/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { Valuation } from "./Valuation";
import {
  ValuationFromJSON,
  ValuationFromJSONTyped,
  ValuationToJSON,
} from "./Valuation";
import type { SubAccountItemMetadataNode } from "./SubAccountItemMetadataNode";
import {
  SubAccountItemMetadataNodeFromJSON,
  SubAccountItemMetadataNodeFromJSONTyped,
  SubAccountItemMetadataNodeToJSON,
} from "./SubAccountItemMetadataNode";
import type { SubAccountItemDescription } from "./SubAccountItemDescription";
import {
  SubAccountItemDescriptionFromJSON,
  SubAccountItemDescriptionFromJSONTyped,
  SubAccountItemDescriptionToJSON,
} from "./SubAccountItemDescription";

/**
 *
 * @export
 * @interface SubAccountItemNode
 */
export interface SubAccountItemNode {
  /**
   *
   * @type {string}
   * @memberof SubAccountItemNode
   */
  role?: SubAccountItemNodeRoleEnum;
  /**
   *
   * @type {SubAccountItemDescription}
   * @memberof SubAccountItemNode
   */
  item: SubAccountItemDescription;
  /**
   *
   * @type {Valuation}
   * @memberof SubAccountItemNode
   */
  valuation: Valuation;
  /**
   *
   * @type {Array<SubAccountItemMetadataNode>}
   * @memberof SubAccountItemNode
   */
  children: Array<SubAccountItemMetadataNode>;
}

/**
 * @export
 */
export const SubAccountItemNodeRoleEnum = {
  Item: "item",
} as const;
export type SubAccountItemNodeRoleEnum =
  (typeof SubAccountItemNodeRoleEnum)[keyof typeof SubAccountItemNodeRoleEnum];

/**
 * Check if a given object implements the SubAccountItemNode interface.
 */
export function instanceOfSubAccountItemNode(
  value: object,
): value is SubAccountItemNode {
  if (!("item" in value) || value["item"] === undefined) return false;
  if (!("valuation" in value) || value["valuation"] === undefined) return false;
  if (!("children" in value) || value["children"] === undefined) return false;
  return true;
}

export function SubAccountItemNodeFromJSON(json: any): SubAccountItemNode {
  return SubAccountItemNodeFromJSONTyped(json, false);
}

export function SubAccountItemNodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SubAccountItemNode {
  if (json == null) {
    return json;
  }
  return {
    role: json["role"] == null ? undefined : json["role"],
    item: SubAccountItemDescriptionFromJSON(json["item"]),
    valuation: ValuationFromJSON(json["valuation"]),
    children: (json["children"] as Array<any>).map(
      SubAccountItemMetadataNodeFromJSON,
    ),
  };
}

export function SubAccountItemNodeToJSON(
  value?: SubAccountItemNode | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    role: value["role"],
    item: SubAccountItemDescriptionToJSON(value["item"]),
    valuation: ValuationToJSON(value["valuation"]),
    children: (value["children"] as Array<any>).map(
      SubAccountItemMetadataNodeToJSON,
    ),
  };
}
