/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserAccount
 */
export interface UserAccount {
  /**
   *
   * @type {number}
   * @memberof UserAccount
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserAccount
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserAccount
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof UserAccount
   */
  mobilePhoneNumber?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserAccount
   */
  isDemo: boolean;
  /**
   *
   * @type {Date}
   * @memberof UserAccount
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof UserAccount
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the UserAccount interface.
 */
export function instanceOfUserAccount(value: object): value is UserAccount {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("email" in value) || value["email"] === undefined) return false;
  if (!("fullName" in value) || value["fullName"] === undefined) return false;
  if (!("isDemo" in value) || value["isDemo"] === undefined) return false;
  if (!("createdAt" in value) || value["createdAt"] === undefined) return false;
  return true;
}

export function UserAccountFromJSON(json: any): UserAccount {
  return UserAccountFromJSONTyped(json, false);
}

export function UserAccountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserAccount {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    email: json["email"],
    fullName: json["full_name"],
    mobilePhoneNumber:
      json["mobile_phone_number"] == null
        ? undefined
        : json["mobile_phone_number"],
    isDemo: json["is_demo"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
  };
}

export function UserAccountToJSON(value?: UserAccount | null): any {
  if (value == null) {
    return value;
  }
  return {
    id: value["id"],
    email: value["email"],
    full_name: value["fullName"],
    mobile_phone_number: value["mobilePhoneNumber"],
    is_demo: value["isDemo"],
    created_at: value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
  };
}
