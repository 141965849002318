/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LinkedAccountValuationLinkedAccountDescription
 */
export interface LinkedAccountValuationLinkedAccountDescription {
  /**
   *
   * @type {number}
   * @memberof LinkedAccountValuationLinkedAccountDescription
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof LinkedAccountValuationLinkedAccountDescription
   */
  providerId: string;
  /**
   *
   * @type {string}
   * @memberof LinkedAccountValuationLinkedAccountDescription
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof LinkedAccountValuationLinkedAccountDescription
   */
  accountColour: string;
}

/**
 * Check if a given object implements the LinkedAccountValuationLinkedAccountDescription interface.
 */
export function instanceOfLinkedAccountValuationLinkedAccountDescription(
  value: object,
): value is LinkedAccountValuationLinkedAccountDescription {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("providerId" in value) || value["providerId"] === undefined)
    return false;
  if (!("description" in value) || value["description"] === undefined)
    return false;
  if (!("accountColour" in value) || value["accountColour"] === undefined)
    return false;
  return true;
}

export function LinkedAccountValuationLinkedAccountDescriptionFromJSON(
  json: any,
): LinkedAccountValuationLinkedAccountDescription {
  return LinkedAccountValuationLinkedAccountDescriptionFromJSONTyped(
    json,
    false,
  );
}

export function LinkedAccountValuationLinkedAccountDescriptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LinkedAccountValuationLinkedAccountDescription {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    providerId: json["provider_id"],
    description: json["description"],
    accountColour: json["account_colour"],
  };
}

export function LinkedAccountValuationLinkedAccountDescriptionToJSON(
  value?: LinkedAccountValuationLinkedAccountDescription | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    id: value["id"],
    provider_id: value["providerId"],
    description: value["description"],
    account_colour: value["accountColour"],
  };
}
