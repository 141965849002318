/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Metrics
 */
export interface Metrics {
  /**
   *
   * @type {Date}
   * @memberof Metrics
   */
  firstDate: Date;
  /**
   *
   * @type {number}
   * @memberof Metrics
   */
  firstValue: number;
  /**
   *
   * @type {Date}
   * @memberof Metrics
   */
  lastDate: Date;
  /**
   *
   * @type {number}
   * @memberof Metrics
   */
  lastValue: number;
  /**
   *
   * @type {number}
   * @memberof Metrics
   */
  minValue: number;
  /**
   *
   * @type {number}
   * @memberof Metrics
   */
  maxValue: number;
  /**
   *
   * @type {number}
   * @memberof Metrics
   */
  absChange: number;
  /**
   *
   * @type {number}
   * @memberof Metrics
   */
  relChange: number;
}

/**
 * Check if a given object implements the Metrics interface.
 */
export function instanceOfMetrics(value: object): value is Metrics {
  if (!("firstDate" in value) || value["firstDate"] === undefined) return false;
  if (!("firstValue" in value) || value["firstValue"] === undefined)
    return false;
  if (!("lastDate" in value) || value["lastDate"] === undefined) return false;
  if (!("lastValue" in value) || value["lastValue"] === undefined) return false;
  if (!("minValue" in value) || value["minValue"] === undefined) return false;
  if (!("maxValue" in value) || value["maxValue"] === undefined) return false;
  if (!("absChange" in value) || value["absChange"] === undefined) return false;
  if (!("relChange" in value) || value["relChange"] === undefined) return false;
  return true;
}

export function MetricsFromJSON(json: any): Metrics {
  return MetricsFromJSONTyped(json, false);
}

export function MetricsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Metrics {
  if (json == null) {
    return json;
  }
  return {
    firstDate: new Date(json["first_date"]),
    firstValue: json["first_value"],
    lastDate: new Date(json["last_date"]),
    lastValue: json["last_value"],
    minValue: json["min_value"],
    maxValue: json["max_value"],
    absChange: json["abs_change"],
    relChange: json["rel_change"],
  };
}

export function MetricsToJSON(value?: Metrics | null): any {
  if (value == null) {
    return value;
  }
  return {
    first_date: value["firstDate"].toISOString(),
    first_value: value["firstValue"],
    last_date: value["lastDate"].toISOString(),
    last_value: value["lastValue"],
    min_value: value["minValue"],
    max_value: value["maxValue"],
    abs_change: value["absChange"],
    rel_change: value["relChange"],
  };
}
