/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserAccountCreationSettings
 */
export interface UserAccountCreationSettings {
  /**
   *
   * @type {string}
   * @memberof UserAccountCreationSettings
   */
  valuationCcy: string;
}

/**
 * Check if a given object implements the UserAccountCreationSettings interface.
 */
export function instanceOfUserAccountCreationSettings(
  value: object,
): value is UserAccountCreationSettings {
  if (!("valuationCcy" in value) || value["valuationCcy"] === undefined)
    return false;
  return true;
}

export function UserAccountCreationSettingsFromJSON(
  json: any,
): UserAccountCreationSettings {
  return UserAccountCreationSettingsFromJSONTyped(json, false);
}

export function UserAccountCreationSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserAccountCreationSettings {
  if (json == null) {
    return json;
  }
  return {
    valuationCcy: json["valuation_ccy"],
  };
}

export function UserAccountCreationSettingsToJSON(
  value?: UserAccountCreationSettings | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    valuation_ccy: value["valuationCcy"],
  };
}
