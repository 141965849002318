/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { UserAccountProfile } from "./UserAccountProfile";
import {
  UserAccountProfileFromJSON,
  UserAccountProfileFromJSONTyped,
  UserAccountProfileToJSON,
} from "./UserAccountProfile";

/**
 *
 * @export
 * @interface AppUpdateUserAccountProfileResponse
 */
export interface AppUpdateUserAccountProfileResponse {
  /**
   *
   * @type {UserAccountProfile}
   * @memberof AppUpdateUserAccountProfileResponse
   */
  profile: UserAccountProfile;
}

/**
 * Check if a given object implements the AppUpdateUserAccountProfileResponse interface.
 */
export function instanceOfAppUpdateUserAccountProfileResponse(
  value: object,
): value is AppUpdateUserAccountProfileResponse {
  if (!("profile" in value) || value["profile"] === undefined) return false;
  return true;
}

export function AppUpdateUserAccountProfileResponseFromJSON(
  json: any,
): AppUpdateUserAccountProfileResponse {
  return AppUpdateUserAccountProfileResponseFromJSONTyped(json, false);
}

export function AppUpdateUserAccountProfileResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppUpdateUserAccountProfileResponse {
  if (json == null) {
    return json;
  }
  return {
    profile: UserAccountProfileFromJSON(json["profile"]),
  };
}

export function AppUpdateUserAccountProfileResponseToJSON(
  value?: AppUpdateUserAccountProfileResponse | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    profile: UserAccountProfileToJSON(value["profile"]),
  };
}
