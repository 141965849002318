/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface EmailProviderMetadata
 */
export interface EmailProviderMetadata {
  /**
   *
   * @type {string}
   * @memberof EmailProviderMetadata
   */
  providerId: string;
  /**
   *
   * @type {string}
   * @memberof EmailProviderMetadata
   */
  description: string;
  /**
   *
   * @type {object}
   * @memberof EmailProviderMetadata
   */
  settingsSchema: object;
}

/**
 * Check if a given object implements the EmailProviderMetadata interface.
 */
export function instanceOfEmailProviderMetadata(
  value: object,
): value is EmailProviderMetadata {
  if (!("providerId" in value) || value["providerId"] === undefined)
    return false;
  if (!("description" in value) || value["description"] === undefined)
    return false;
  if (!("settingsSchema" in value) || value["settingsSchema"] === undefined)
    return false;
  return true;
}

export function EmailProviderMetadataFromJSON(
  json: any,
): EmailProviderMetadata {
  return EmailProviderMetadataFromJSONTyped(json, false);
}

export function EmailProviderMetadataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EmailProviderMetadata {
  if (json == null) {
    return json;
  }
  return {
    providerId: json["provider_id"],
    description: json["description"],
    settingsSchema: json["settings_schema"],
  };
}

export function EmailProviderMetadataToJSON(
  value?: EmailProviderMetadata | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    provider_id: value["providerId"],
    description: value["description"],
    settings_schema: value["settingsSchema"],
  };
}
