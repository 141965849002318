/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { UserAccountSettings } from "./UserAccountSettings";
import {
  UserAccountSettingsFromJSON,
  UserAccountSettingsFromJSONTyped,
  UserAccountSettingsToJSON,
} from "./UserAccountSettings";

/**
 *
 * @export
 * @interface AppGetUserAccountSettingsResponse
 */
export interface AppGetUserAccountSettingsResponse {
  /**
   *
   * @type {UserAccountSettings}
   * @memberof AppGetUserAccountSettingsResponse
   */
  settings: UserAccountSettings;
}

/**
 * Check if a given object implements the AppGetUserAccountSettingsResponse interface.
 */
export function instanceOfAppGetUserAccountSettingsResponse(
  value: object,
): value is AppGetUserAccountSettingsResponse {
  if (!("settings" in value) || value["settings"] === undefined) return false;
  return true;
}

export function AppGetUserAccountSettingsResponseFromJSON(
  json: any,
): AppGetUserAccountSettingsResponse {
  return AppGetUserAccountSettingsResponseFromJSONTyped(json, false);
}

export function AppGetUserAccountSettingsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppGetUserAccountSettingsResponse {
  if (json == null) {
    return json;
  }
  return {
    settings: UserAccountSettingsFromJSON(json["settings"]),
  };
}

export function AppGetUserAccountSettingsResponseToJSON(
  value?: AppGetUserAccountSettingsResponse | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    settings: UserAccountSettingsToJSON(value["settings"]),
  };
}
