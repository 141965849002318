/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AppLoginRequest
 */
export interface AppLoginRequest {
  /**
   *
   * @type {string}
   * @memberof AppLoginRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AppLoginRequest
   */
  password: string;
}

/**
 * Check if a given object implements the AppLoginRequest interface.
 */
export function instanceOfAppLoginRequest(
  value: object,
): value is AppLoginRequest {
  if (!("email" in value) || value["email"] === undefined) return false;
  if (!("password" in value) || value["password"] === undefined) return false;
  return true;
}

export function AppLoginRequestFromJSON(json: any): AppLoginRequest {
  return AppLoginRequestFromJSONTyped(json, false);
}

export function AppLoginRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppLoginRequest {
  if (json == null) {
    return json;
  }
  return {
    email: json["email"],
    password: json["password"],
  };
}

export function AppLoginRequestToJSON(value?: AppLoginRequest | null): any {
  if (value == null) {
    return value;
  }
  return {
    email: value["email"],
    password: value["password"],
  };
}
