/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { XAxisDescription } from "./XAxisDescription";
import {
  XAxisDescriptionFromJSON,
  XAxisDescriptionFromJSONTyped,
  XAxisDescriptionToJSON,
} from "./XAxisDescription";
import type { SeriesDescription } from "./SeriesDescription";
import {
  SeriesDescriptionFromJSON,
  SeriesDescriptionFromJSONTyped,
  SeriesDescriptionToJSON,
} from "./SeriesDescription";

/**
 *
 * @export
 * @interface SeriesData
 */
export interface SeriesData {
  /**
   *
   * @type {XAxisDescription}
   * @memberof SeriesData
   */
  xAxis: XAxisDescription;
  /**
   *
   * @type {Array<SeriesDescription>}
   * @memberof SeriesData
   */
  series: Array<SeriesDescription>;
}

/**
 * Check if a given object implements the SeriesData interface.
 */
export function instanceOfSeriesData(value: object): value is SeriesData {
  if (!("xAxis" in value) || value["xAxis"] === undefined) return false;
  if (!("series" in value) || value["series"] === undefined) return false;
  return true;
}

export function SeriesDataFromJSON(json: any): SeriesData {
  return SeriesDataFromJSONTyped(json, false);
}

export function SeriesDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SeriesData {
  if (json == null) {
    return json;
  }
  return {
    xAxis: XAxisDescriptionFromJSON(json["x_axis"]),
    series: (json["series"] as Array<any>).map(SeriesDescriptionFromJSON),
  };
}

export function SeriesDataToJSON(value?: SeriesData | null): any {
  if (value == null) {
    return value;
  }
  return {
    x_axis: XAxisDescriptionToJSON(value["xAxis"]),
    series: (value["series"] as Array<any>).map(SeriesDescriptionToJSON),
  };
}
