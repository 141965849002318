/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { SubAccountItemNodeIcon } from "./SubAccountItemNodeIcon";
import {
  SubAccountItemNodeIconFromJSON,
  SubAccountItemNodeIconFromJSONTyped,
  SubAccountItemNodeIconToJSON,
} from "./SubAccountItemNodeIcon";

/**
 *
 * @export
 * @interface SubAccountItemDescription
 */
export interface SubAccountItemDescription {
  /**
   *
   * @type {string}
   * @memberof SubAccountItemDescription
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SubAccountItemDescription
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof SubAccountItemDescription
   */
  subType: string;
  /**
   *
   * @type {string}
   * @memberof SubAccountItemDescription
   */
  assetClass?: string;
  /**
   *
   * @type {string}
   * @memberof SubAccountItemDescription
   */
  assetType?: string;
  /**
   *
   * @type {SubAccountItemNodeIcon}
   * @memberof SubAccountItemDescription
   */
  icon?: SubAccountItemNodeIcon;
}

/**
 * Check if a given object implements the SubAccountItemDescription interface.
 */
export function instanceOfSubAccountItemDescription(
  value: object,
): value is SubAccountItemDescription {
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("type" in value) || value["type"] === undefined) return false;
  if (!("subType" in value) || value["subType"] === undefined) return false;
  return true;
}

export function SubAccountItemDescriptionFromJSON(
  json: any,
): SubAccountItemDescription {
  return SubAccountItemDescriptionFromJSONTyped(json, false);
}

export function SubAccountItemDescriptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SubAccountItemDescription {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"],
    type: json["type"],
    subType: json["sub_type"],
    assetClass: json["asset_class"] == null ? undefined : json["asset_class"],
    assetType: json["asset_type"] == null ? undefined : json["asset_type"],
    icon:
      json["icon"] == null
        ? undefined
        : SubAccountItemNodeIconFromJSON(json["icon"]),
  };
}

export function SubAccountItemDescriptionToJSON(
  value?: SubAccountItemDescription | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    name: value["name"],
    type: value["type"],
    sub_type: value["subType"],
    asset_class: value["assetClass"],
    asset_type: value["assetType"],
    icon: SubAccountItemNodeIconToJSON(value["icon"]),
  };
}
