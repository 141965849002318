/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { LinkedAccountValuation } from "./LinkedAccountValuation";
import {
  LinkedAccountValuationFromJSON,
  LinkedAccountValuationFromJSONTyped,
  LinkedAccountValuationToJSON,
} from "./LinkedAccountValuation";
import type { LinkedAccountValuationLinkedAccountDescription } from "./LinkedAccountValuationLinkedAccountDescription";
import {
  LinkedAccountValuationLinkedAccountDescriptionFromJSON,
  LinkedAccountValuationLinkedAccountDescriptionFromJSONTyped,
  LinkedAccountValuationLinkedAccountDescriptionToJSON,
} from "./LinkedAccountValuationLinkedAccountDescription";

/**
 *
 * @export
 * @interface LinkedAccountValuationEntry
 */
export interface LinkedAccountValuationEntry {
  /**
   *
   * @type {LinkedAccountValuationLinkedAccountDescription}
   * @memberof LinkedAccountValuationEntry
   */
  linkedAccount: LinkedAccountValuationLinkedAccountDescription;
  /**
   *
   * @type {LinkedAccountValuation}
   * @memberof LinkedAccountValuationEntry
   */
  valuation: LinkedAccountValuation;
}

/**
 * Check if a given object implements the LinkedAccountValuationEntry interface.
 */
export function instanceOfLinkedAccountValuationEntry(
  value: object,
): value is LinkedAccountValuationEntry {
  if (!("linkedAccount" in value) || value["linkedAccount"] === undefined)
    return false;
  if (!("valuation" in value) || value["valuation"] === undefined) return false;
  return true;
}

export function LinkedAccountValuationEntryFromJSON(
  json: any,
): LinkedAccountValuationEntry {
  return LinkedAccountValuationEntryFromJSONTyped(json, false);
}

export function LinkedAccountValuationEntryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LinkedAccountValuationEntry {
  if (json == null) {
    return json;
  }
  return {
    linkedAccount: LinkedAccountValuationLinkedAccountDescriptionFromJSON(
      json["linked_account"],
    ),
    valuation: LinkedAccountValuationFromJSON(json["valuation"]),
  };
}

export function LinkedAccountValuationEntryToJSON(
  value?: LinkedAccountValuationEntry | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    linked_account: LinkedAccountValuationLinkedAccountDescriptionToJSON(
      value["linkedAccount"],
    ),
    valuation: LinkedAccountValuationToJSON(value["valuation"]),
  };
}
