/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AuthenticationPayload
 */
export interface AuthenticationPayload {
  /**
   *
   * @type {string}
   * @memberof AuthenticationPayload
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationPayload
   */
  refreshToken: string;
}

/**
 * Check if a given object implements the AuthenticationPayload interface.
 */
export function instanceOfAuthenticationPayload(
  value: object,
): value is AuthenticationPayload {
  if (!("accessToken" in value) || value["accessToken"] === undefined)
    return false;
  if (!("refreshToken" in value) || value["refreshToken"] === undefined)
    return false;
  return true;
}

export function AuthenticationPayloadFromJSON(
  json: any,
): AuthenticationPayload {
  return AuthenticationPayloadFromJSONTyped(json, false);
}

export function AuthenticationPayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AuthenticationPayload {
  if (json == null) {
    return json;
  }
  return {
    accessToken: json["access_token"],
    refreshToken: json["refresh_token"],
  };
}

export function AuthenticationPayloadToJSON(
  value?: AuthenticationPayload | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    access_token: value["accessToken"],
    refresh_token: value["refreshToken"],
  };
}
