/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface HealthResponse
 */
export interface HealthResponse {
  /**
   *
   * @type {boolean}
   * @memberof HealthResponse
   */
  healthy: boolean;
}

/**
 * Check if a given object implements the HealthResponse interface.
 */
export function instanceOfHealthResponse(
  value: object,
): value is HealthResponse {
  if (!("healthy" in value) || value["healthy"] === undefined) return false;
  return true;
}

export function HealthResponseFromJSON(json: any): HealthResponse {
  return HealthResponseFromJSONTyped(json, false);
}

export function HealthResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HealthResponse {
  if (json == null) {
    return json;
  }
  return {
    healthy: json["healthy"],
  };
}

export function HealthResponseToJSON(value?: HealthResponse | null): any {
  if (value == null) {
    return value;
  }
  return {
    healthy: value["healthy"],
  };
}
