/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { DataInner } from "./DataInner";
import {
  DataInnerFromJSON,
  DataInnerFromJSONTyped,
  DataInnerToJSON,
} from "./DataInner";

/**
 *
 * @export
 * @interface SeriesDescription
 */
export interface SeriesDescription {
  /**
   *
   * @type {string}
   * @memberof SeriesDescription
   */
  name: string;
  /**
   *
   * @type {Array<DataInner>}
   * @memberof SeriesDescription
   */
  data: Array<DataInner>;
  /**
   *
   * @type {string}
   * @memberof SeriesDescription
   */
  colour: string;
}

/**
 * Check if a given object implements the SeriesDescription interface.
 */
export function instanceOfSeriesDescription(
  value: object,
): value is SeriesDescription {
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("data" in value) || value["data"] === undefined) return false;
  if (!("colour" in value) || value["colour"] === undefined) return false;
  return true;
}

export function SeriesDescriptionFromJSON(json: any): SeriesDescription {
  return SeriesDescriptionFromJSONTyped(json, false);
}

export function SeriesDescriptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SeriesDescription {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"],
    data: (json["data"] as Array<any>).map(DataInnerFromJSON),
    colour: json["colour"],
  };
}

export function SeriesDescriptionToJSON(value?: SeriesDescription | null): any {
  if (value == null) {
    return value;
  }
  return {
    name: value["name"],
    data: (value["data"] as Array<any>).map(DataInnerToJSON),
    colour: value["colour"],
  };
}
