/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserAccountValuationSparklineEntry
 */
export interface UserAccountValuationSparklineEntry {
  /**
   *
   * @type {Date}
   * @memberof UserAccountValuationSparklineEntry
   */
  effectiveAt: Date;
  /**
   *
   * @type {number}
   * @memberof UserAccountValuationSparklineEntry
   */
  value?: number;
}

/**
 * Check if a given object implements the UserAccountValuationSparklineEntry interface.
 */
export function instanceOfUserAccountValuationSparklineEntry(
  value: object,
): value is UserAccountValuationSparklineEntry {
  if (!("effectiveAt" in value) || value["effectiveAt"] === undefined)
    return false;
  return true;
}

export function UserAccountValuationSparklineEntryFromJSON(
  json: any,
): UserAccountValuationSparklineEntry {
  return UserAccountValuationSparklineEntryFromJSONTyped(json, false);
}

export function UserAccountValuationSparklineEntryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserAccountValuationSparklineEntry {
  if (json == null) {
    return json;
  }
  return {
    effectiveAt: new Date(json["effective_at"]),
    value: json["value"] == null ? undefined : json["value"],
  };
}

export function UserAccountValuationSparklineEntryToJSON(
  value?: UserAccountValuationSparklineEntry | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    effective_at: value["effectiveAt"].toISOString(),
    value: value["value"],
  };
}
