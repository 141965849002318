/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { ValuationChange } from "./ValuationChange";
import {
  ValuationChangeFromJSON,
  ValuationChangeFromJSONTyped,
  ValuationChangeToJSON,
} from "./ValuationChange";

/**
 *
 * @export
 * @interface Valuation
 */
export interface Valuation {
  /**
   *
   * @type {string}
   * @memberof Valuation
   */
  currency: string;
  /**
   *
   * @type {number}
   * @memberof Valuation
   */
  value: number;
  /**
   *
   * @type {ValuationChange}
   * @memberof Valuation
   */
  change: ValuationChange;
}

/**
 * Check if a given object implements the Valuation interface.
 */
export function instanceOfValuation(value: object): value is Valuation {
  if (!("currency" in value) || value["currency"] === undefined) return false;
  if (!("value" in value) || value["value"] === undefined) return false;
  if (!("change" in value) || value["change"] === undefined) return false;
  return true;
}

export function ValuationFromJSON(json: any): Valuation {
  return ValuationFromJSONTyped(json, false);
}

export function ValuationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Valuation {
  if (json == null) {
    return json;
  }
  return {
    currency: json["currency"],
    value: json["value"],
    change: ValuationChangeFromJSON(json["change"]),
  };
}

export function ValuationToJSON(value?: Valuation | null): any {
  if (value == null) {
    return value;
  }
  return {
    currency: value["currency"],
    value: value["value"],
    change: ValuationChangeToJSON(value["change"]),
  };
}
