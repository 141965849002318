/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { HistoricalValuation } from "./HistoricalValuation";
import {
  HistoricalValuationFromJSON,
  HistoricalValuationFromJSONTyped,
  HistoricalValuationToJSON,
} from "./HistoricalValuation";

/**
 *
 * @export
 * @interface AppGetUserAccountValuationHistoryResponse
 */
export interface AppGetUserAccountValuationHistoryResponse {
  /**
   *
   * @type {HistoricalValuation}
   * @memberof AppGetUserAccountValuationHistoryResponse
   */
  historicalValuation: HistoricalValuation;
}

/**
 * Check if a given object implements the AppGetUserAccountValuationHistoryResponse interface.
 */
export function instanceOfAppGetUserAccountValuationHistoryResponse(
  value: object,
): value is AppGetUserAccountValuationHistoryResponse {
  if (
    !("historicalValuation" in value) ||
    value["historicalValuation"] === undefined
  )
    return false;
  return true;
}

export function AppGetUserAccountValuationHistoryResponseFromJSON(
  json: any,
): AppGetUserAccountValuationHistoryResponse {
  return AppGetUserAccountValuationHistoryResponseFromJSONTyped(json, false);
}

export function AppGetUserAccountValuationHistoryResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppGetUserAccountValuationHistoryResponse {
  if (json == null) {
    return json;
  }
  return {
    historicalValuation: HistoricalValuationFromJSON(
      json["historical_valuation"],
    ),
  };
}

export function AppGetUserAccountValuationHistoryResponseToJSON(
  value?: AppGetUserAccountValuationHistoryResponse | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    historical_valuation: HistoricalValuationToJSON(
      value["historicalValuation"],
    ),
  };
}
