/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { LinkedAccountDescription } from "./LinkedAccountDescription";
import {
  LinkedAccountDescriptionFromJSON,
  LinkedAccountDescriptionFromJSONTyped,
  LinkedAccountDescriptionToJSON,
} from "./LinkedAccountDescription";
import type { ValuationWithSparkline } from "./ValuationWithSparkline";
import {
  ValuationWithSparklineFromJSON,
  ValuationWithSparklineFromJSONTyped,
  ValuationWithSparklineToJSON,
} from "./ValuationWithSparkline";
import type { SubAccountNode } from "./SubAccountNode";
import {
  SubAccountNodeFromJSON,
  SubAccountNodeFromJSONTyped,
  SubAccountNodeToJSON,
} from "./SubAccountNode";

/**
 *
 * @export
 * @interface LinkedAccountNode
 */
export interface LinkedAccountNode {
  /**
   *
   * @type {string}
   * @memberof LinkedAccountNode
   */
  role?: LinkedAccountNodeRoleEnum;
  /**
   *
   * @type {LinkedAccountDescription}
   * @memberof LinkedAccountNode
   */
  linkedAccount: LinkedAccountDescription;
  /**
   *
   * @type {ValuationWithSparkline}
   * @memberof LinkedAccountNode
   */
  valuation: ValuationWithSparkline;
  /**
   *
   * @type {Array<SubAccountNode>}
   * @memberof LinkedAccountNode
   */
  children: Array<SubAccountNode>;
}

/**
 * @export
 */
export const LinkedAccountNodeRoleEnum = {
  LinkedAccount: "linked_account",
} as const;
export type LinkedAccountNodeRoleEnum =
  (typeof LinkedAccountNodeRoleEnum)[keyof typeof LinkedAccountNodeRoleEnum];

/**
 * Check if a given object implements the LinkedAccountNode interface.
 */
export function instanceOfLinkedAccountNode(
  value: object,
): value is LinkedAccountNode {
  if (!("linkedAccount" in value) || value["linkedAccount"] === undefined)
    return false;
  if (!("valuation" in value) || value["valuation"] === undefined) return false;
  if (!("children" in value) || value["children"] === undefined) return false;
  return true;
}

export function LinkedAccountNodeFromJSON(json: any): LinkedAccountNode {
  return LinkedAccountNodeFromJSONTyped(json, false);
}

export function LinkedAccountNodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LinkedAccountNode {
  if (json == null) {
    return json;
  }
  return {
    role: json["role"] == null ? undefined : json["role"],
    linkedAccount: LinkedAccountDescriptionFromJSON(json["linked_account"]),
    valuation: ValuationWithSparklineFromJSON(json["valuation"]),
    children: (json["children"] as Array<any>).map(SubAccountNodeFromJSON),
  };
}

export function LinkedAccountNodeToJSON(value?: LinkedAccountNode | null): any {
  if (value == null) {
    return value;
  }
  return {
    role: value["role"],
    linked_account: LinkedAccountDescriptionToJSON(value["linkedAccount"]),
    valuation: ValuationWithSparklineToJSON(value["valuation"]),
    children: (value["children"] as Array<any>).map(SubAccountNodeToJSON),
  };
}
