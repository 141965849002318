/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AggregationDescription
 */
export interface AggregationDescription {
  /**
   *
   * @type {string}
   * @memberof AggregationDescription
   */
  asStr: string;
}

/**
 * Check if a given object implements the AggregationDescription interface.
 */
export function instanceOfAggregationDescription(
  value: object,
): value is AggregationDescription {
  if (!("asStr" in value) || value["asStr"] === undefined) return false;
  return true;
}

export function AggregationDescriptionFromJSON(
  json: any,
): AggregationDescription {
  return AggregationDescriptionFromJSONTyped(json, false);
}

export function AggregationDescriptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AggregationDescription {
  if (json == null) {
    return json;
  }
  return {
    asStr: json["as_str"],
  };
}

export function AggregationDescriptionToJSON(
  value?: AggregationDescription | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    as_str: value["asStr"],
  };
}
