/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { LinkedAccountStatus } from "./LinkedAccountStatus";
import {
  LinkedAccountStatusFromJSON,
  LinkedAccountStatusFromJSONTyped,
  LinkedAccountStatusToJSON,
} from "./LinkedAccountStatus";
import type { Provider } from "./Provider";
import {
  ProviderFromJSON,
  ProviderFromJSONTyped,
  ProviderToJSON,
} from "./Provider";

/**
 *
 * @export
 * @interface LinkedAccount
 */
export interface LinkedAccount {
  /**
   *
   * @type {number}
   * @memberof LinkedAccount
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof LinkedAccount
   */
  userAccountId: number;
  /**
   *
   * @type {string}
   * @memberof LinkedAccount
   */
  accountName: string;
  /**
   *
   * @type {string}
   * @memberof LinkedAccount
   */
  accountColour: string;
  /**
   *
   * @type {boolean}
   * @memberof LinkedAccount
   */
  deleted: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LinkedAccount
   */
  frozen: boolean;
  /**
   *
   * @type {string}
   * @memberof LinkedAccount
   */
  providerId: string;
  /**
   *
   * @type {Provider}
   * @memberof LinkedAccount
   */
  provider: Provider;
  /**
   *
   * @type {LinkedAccountStatus}
   * @memberof LinkedAccount
   */
  status?: LinkedAccountStatus;
  /**
   *
   * @type {object}
   * @memberof LinkedAccount
   */
  credentials?: object;
  /**
   *
   * @type {Date}
   * @memberof LinkedAccount
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof LinkedAccount
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the LinkedAccount interface.
 */
export function instanceOfLinkedAccount(value: object): value is LinkedAccount {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("userAccountId" in value) || value["userAccountId"] === undefined)
    return false;
  if (!("accountName" in value) || value["accountName"] === undefined)
    return false;
  if (!("accountColour" in value) || value["accountColour"] === undefined)
    return false;
  if (!("deleted" in value) || value["deleted"] === undefined) return false;
  if (!("frozen" in value) || value["frozen"] === undefined) return false;
  if (!("providerId" in value) || value["providerId"] === undefined)
    return false;
  if (!("provider" in value) || value["provider"] === undefined) return false;
  if (!("createdAt" in value) || value["createdAt"] === undefined) return false;
  return true;
}

export function LinkedAccountFromJSON(json: any): LinkedAccount {
  return LinkedAccountFromJSONTyped(json, false);
}

export function LinkedAccountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LinkedAccount {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    userAccountId: json["user_account_id"],
    accountName: json["account_name"],
    accountColour: json["account_colour"],
    deleted: json["deleted"],
    frozen: json["frozen"],
    providerId: json["provider_id"],
    provider: ProviderFromJSON(json["provider"]),
    status:
      json["status"] == null
        ? undefined
        : LinkedAccountStatusFromJSON(json["status"]),
    credentials: json["credentials"] == null ? undefined : json["credentials"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
  };
}

export function LinkedAccountToJSON(value?: LinkedAccount | null): any {
  if (value == null) {
    return value;
  }
  return {
    id: value["id"],
    user_account_id: value["userAccountId"],
    account_name: value["accountName"],
    account_colour: value["accountColour"],
    deleted: value["deleted"],
    frozen: value["frozen"],
    provider_id: value["providerId"],
    provider: ProviderToJSON(value["provider"]),
    status: LinkedAccountStatusToJSON(value["status"]),
    credentials: value["credentials"],
    created_at: value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
  };
}
