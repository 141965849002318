/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AppEmailDeliverySettings
 */
export interface AppEmailDeliverySettings {
  /**
   *
   * @type {string}
   * @memberof AppEmailDeliverySettings
   */
  subjectPrefix: string;
  /**
   *
   * @type {string}
   * @memberof AppEmailDeliverySettings
   */
  senderName: string;
  /**
   *
   * @type {string}
   * @memberof AppEmailDeliverySettings
   */
  providerId: string;
  /**
   *
   * @type {object}
   * @memberof AppEmailDeliverySettings
   */
  providerSettings: object;
}

/**
 * Check if a given object implements the AppEmailDeliverySettings interface.
 */
export function instanceOfAppEmailDeliverySettings(
  value: object,
): value is AppEmailDeliverySettings {
  if (!("subjectPrefix" in value) || value["subjectPrefix"] === undefined)
    return false;
  if (!("senderName" in value) || value["senderName"] === undefined)
    return false;
  if (!("providerId" in value) || value["providerId"] === undefined)
    return false;
  if (!("providerSettings" in value) || value["providerSettings"] === undefined)
    return false;
  return true;
}

export function AppEmailDeliverySettingsFromJSON(
  json: any,
): AppEmailDeliverySettings {
  return AppEmailDeliverySettingsFromJSONTyped(json, false);
}

export function AppEmailDeliverySettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppEmailDeliverySettings {
  if (json == null) {
    return json;
  }
  return {
    subjectPrefix: json["subject_prefix"],
    senderName: json["sender_name"],
    providerId: json["provider_id"],
    providerSettings: json["provider_settings"],
  };
}

export function AppEmailDeliverySettingsToJSON(
  value?: AppEmailDeliverySettings | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    subject_prefix: value["subjectPrefix"],
    sender_name: value["senderName"],
    provider_id: value["providerId"],
    provider_settings: value["providerSettings"],
  };
}
