/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Provider
 */
export interface Provider {
  /**
   *
   * @type {string}
   * @memberof Provider
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Provider
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof Provider
   */
  websiteUrl: string;
  /**
   *
   * @type {object}
   * @memberof Provider
   */
  credentialsSchema: object;
  /**
   *
   * @type {Date}
   * @memberof Provider
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof Provider
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the Provider interface.
 */
export function instanceOfProvider(value: object): value is Provider {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("description" in value) || value["description"] === undefined)
    return false;
  if (!("websiteUrl" in value) || value["websiteUrl"] === undefined)
    return false;
  if (
    !("credentialsSchema" in value) ||
    value["credentialsSchema"] === undefined
  )
    return false;
  if (!("createdAt" in value) || value["createdAt"] === undefined) return false;
  return true;
}

export function ProviderFromJSON(json: any): Provider {
  return ProviderFromJSONTyped(json, false);
}

export function ProviderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Provider {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    description: json["description"],
    websiteUrl: json["website_url"],
    credentialsSchema: json["credentials_schema"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
  };
}

export function ProviderToJSON(value?: Provider | null): any {
  if (value == null) {
    return value;
  }
  return {
    id: value["id"],
    description: value["description"],
    website_url: value["websiteUrl"],
    credentials_schema: value["credentialsSchema"],
    created_at: value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
  };
}
