/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AppGetAccountsFormattingRulesResponse
 */
export interface AppGetAccountsFormattingRulesResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof AppGetAccountsFormattingRulesResponse
   */
  colourPalette: Array<string>;
}

/**
 * Check if a given object implements the AppGetAccountsFormattingRulesResponse interface.
 */
export function instanceOfAppGetAccountsFormattingRulesResponse(
  value: object,
): value is AppGetAccountsFormattingRulesResponse {
  if (!("colourPalette" in value) || value["colourPalette"] === undefined)
    return false;
  return true;
}

export function AppGetAccountsFormattingRulesResponseFromJSON(
  json: any,
): AppGetAccountsFormattingRulesResponse {
  return AppGetAccountsFormattingRulesResponseFromJSONTyped(json, false);
}

export function AppGetAccountsFormattingRulesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppGetAccountsFormattingRulesResponse {
  if (json == null) {
    return json;
  }
  return {
    colourPalette: json["colour_palette"],
  };
}

export function AppGetAccountsFormattingRulesResponseToJSON(
  value?: AppGetAccountsFormattingRulesResponse | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    colour_palette: value["colourPalette"],
  };
}
