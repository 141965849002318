/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AppLinkAccountRequest
 */
export interface AppLinkAccountRequest {
  /**
   *
   * @type {string}
   * @memberof AppLinkAccountRequest
   */
  providerId: string;
  /**
   *
   * @type {object}
   * @memberof AppLinkAccountRequest
   */
  credentials: object;
  /**
   *
   * @type {string}
   * @memberof AppLinkAccountRequest
   */
  accountName: string;
  /**
   *
   * @type {string}
   * @memberof AppLinkAccountRequest
   */
  accountColour: string;
}

/**
 * Check if a given object implements the AppLinkAccountRequest interface.
 */
export function instanceOfAppLinkAccountRequest(
  value: object,
): value is AppLinkAccountRequest {
  if (!("providerId" in value) || value["providerId"] === undefined)
    return false;
  if (!("credentials" in value) || value["credentials"] === undefined)
    return false;
  if (!("accountName" in value) || value["accountName"] === undefined)
    return false;
  if (!("accountColour" in value) || value["accountColour"] === undefined)
    return false;
  return true;
}

export function AppLinkAccountRequestFromJSON(
  json: any,
): AppLinkAccountRequest {
  return AppLinkAccountRequestFromJSONTyped(json, false);
}

export function AppLinkAccountRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppLinkAccountRequest {
  if (json == null) {
    return json;
  }
  return {
    providerId: json["provider_id"],
    credentials: json["credentials"],
    accountName: json["account_name"],
    accountColour: json["account_colour"],
  };
}

export function AppLinkAccountRequestToJSON(
  value?: AppLinkAccountRequest | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    provider_id: value["providerId"],
    credentials: value["credentials"],
    account_name: value["accountName"],
    account_colour: value["accountColour"],
  };
}
