/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AppUpdateLinkedAccountCredentialsRequest
 */
export interface AppUpdateLinkedAccountCredentialsRequest {
  /**
   *
   * @type {object}
   * @memberof AppUpdateLinkedAccountCredentialsRequest
   */
  credentials: object;
}

/**
 * Check if a given object implements the AppUpdateLinkedAccountCredentialsRequest interface.
 */
export function instanceOfAppUpdateLinkedAccountCredentialsRequest(
  value: object,
): value is AppUpdateLinkedAccountCredentialsRequest {
  if (!("credentials" in value) || value["credentials"] === undefined)
    return false;
  return true;
}

export function AppUpdateLinkedAccountCredentialsRequestFromJSON(
  json: any,
): AppUpdateLinkedAccountCredentialsRequest {
  return AppUpdateLinkedAccountCredentialsRequestFromJSONTyped(json, false);
}

export function AppUpdateLinkedAccountCredentialsRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppUpdateLinkedAccountCredentialsRequest {
  if (json == null) {
    return json;
  }
  return {
    credentials: json["credentials"],
  };
}

export function AppUpdateLinkedAccountCredentialsRequestToJSON(
  value?: AppUpdateLinkedAccountCredentialsRequest | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    credentials: value["credentials"],
  };
}
