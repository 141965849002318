/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AppUpdateLinkedAccountMetadataRequest
 */
export interface AppUpdateLinkedAccountMetadataRequest {
  /**
   *
   * @type {string}
   * @memberof AppUpdateLinkedAccountMetadataRequest
   */
  accountName?: string;
  /**
   *
   * @type {string}
   * @memberof AppUpdateLinkedAccountMetadataRequest
   */
  accountColour?: string;
  /**
   *
   * @type {boolean}
   * @memberof AppUpdateLinkedAccountMetadataRequest
   */
  frozen?: boolean;
}

/**
 * Check if a given object implements the AppUpdateLinkedAccountMetadataRequest interface.
 */
export function instanceOfAppUpdateLinkedAccountMetadataRequest(
  value: object,
): value is AppUpdateLinkedAccountMetadataRequest {
  return true;
}

export function AppUpdateLinkedAccountMetadataRequestFromJSON(
  json: any,
): AppUpdateLinkedAccountMetadataRequest {
  return AppUpdateLinkedAccountMetadataRequestFromJSONTyped(json, false);
}

export function AppUpdateLinkedAccountMetadataRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppUpdateLinkedAccountMetadataRequest {
  if (json == null) {
    return json;
  }
  return {
    accountName:
      json["account_name"] == null ? undefined : json["account_name"],
    accountColour:
      json["account_colour"] == null ? undefined : json["account_colour"],
    frozen: json["frozen"] == null ? undefined : json["frozen"],
  };
}

export function AppUpdateLinkedAccountMetadataRequestToJSON(
  value?: AppUpdateLinkedAccountMetadataRequest | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    account_name: value["accountName"],
    account_colour: value["accountColour"],
    frozen: value["frozen"],
  };
}
