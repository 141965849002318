/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { LinkedAccount } from "./LinkedAccount";
import {
  LinkedAccountFromJSON,
  LinkedAccountFromJSONTyped,
  LinkedAccountToJSON,
} from "./LinkedAccount";

/**
 *
 * @export
 * @interface AppGetLinkedAccountResponse
 */
export interface AppGetLinkedAccountResponse {
  /**
   *
   * @type {LinkedAccount}
   * @memberof AppGetLinkedAccountResponse
   */
  linkedAccount: LinkedAccount;
}

/**
 * Check if a given object implements the AppGetLinkedAccountResponse interface.
 */
export function instanceOfAppGetLinkedAccountResponse(
  value: object,
): value is AppGetLinkedAccountResponse {
  if (!("linkedAccount" in value) || value["linkedAccount"] === undefined)
    return false;
  return true;
}

export function AppGetLinkedAccountResponseFromJSON(
  json: any,
): AppGetLinkedAccountResponse {
  return AppGetLinkedAccountResponseFromJSONTyped(json, false);
}

export function AppGetLinkedAccountResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppGetLinkedAccountResponse {
  if (json == null) {
    return json;
  }
  return {
    linkedAccount: LinkedAccountFromJSON(json["linked_account"]),
  };
}

export function AppGetLinkedAccountResponseToJSON(
  value?: AppGetLinkedAccountResponse | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    linked_account: LinkedAccountToJSON(value["linkedAccount"]),
  };
}
