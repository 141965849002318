/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { ValuationTree } from "./ValuationTree";
import {
  ValuationTreeFromJSON,
  ValuationTreeFromJSONTyped,
  ValuationTreeToJSON,
} from "./ValuationTree";

/**
 *
 * @export
 * @interface AppGetHoldingsReportResponse
 */
export interface AppGetHoldingsReportResponse {
  /**
   *
   * @type {ValuationTree}
   * @memberof AppGetHoldingsReportResponse
   */
  report: ValuationTree;
}

/**
 * Check if a given object implements the AppGetHoldingsReportResponse interface.
 */
export function instanceOfAppGetHoldingsReportResponse(
  value: object,
): value is AppGetHoldingsReportResponse {
  if (!("report" in value) || value["report"] === undefined) return false;
  return true;
}

export function AppGetHoldingsReportResponseFromJSON(
  json: any,
): AppGetHoldingsReportResponse {
  return AppGetHoldingsReportResponseFromJSONTyped(json, false);
}

export function AppGetHoldingsReportResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppGetHoldingsReportResponse {
  if (json == null) {
    return json;
  }
  return {
    report: ValuationTreeFromJSON(json["report"]),
  };
}

export function AppGetHoldingsReportResponseToJSON(
  value?: AppGetHoldingsReportResponse | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    report: ValuationTreeToJSON(value["report"]),
  };
}
