/* tslint:disable */
/* eslint-disable */
/**
 * Finbot application service
 * API documentation for appwsrv
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { PlaidSettings } from "./PlaidSettings";
import {
  PlaidSettingsFromJSON,
  PlaidSettingsFromJSONTyped,
  PlaidSettingsToJSON,
} from "./PlaidSettings";

/**
 *
 * @export
 * @interface AppGetPlaidSettingsResponse
 */
export interface AppGetPlaidSettingsResponse {
  /**
   *
   * @type {PlaidSettings}
   * @memberof AppGetPlaidSettingsResponse
   */
  settings?: PlaidSettings;
}

/**
 * Check if a given object implements the AppGetPlaidSettingsResponse interface.
 */
export function instanceOfAppGetPlaidSettingsResponse(
  value: object,
): value is AppGetPlaidSettingsResponse {
  return true;
}

export function AppGetPlaidSettingsResponseFromJSON(
  json: any,
): AppGetPlaidSettingsResponse {
  return AppGetPlaidSettingsResponseFromJSONTyped(json, false);
}

export function AppGetPlaidSettingsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppGetPlaidSettingsResponse {
  if (json == null) {
    return json;
  }
  return {
    settings:
      json["settings"] == null
        ? undefined
        : PlaidSettingsFromJSON(json["settings"]),
  };
}

export function AppGetPlaidSettingsResponseToJSON(
  value?: AppGetPlaidSettingsResponse | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    settings: PlaidSettingsToJSON(value["settings"]),
  };
}
